* {
  box-sizing: border-box;
}

body,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Fira Code', monospace;
}

html,
body,
#root,
#app {
  height: 100%;
  font-family: 'Fira Code', monospace;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
